const homeData = {
    plug_label: "No setup or retraining required. Benefit from our battle-tested OCR trained on millions of electricity bills.",
    reduce_label: "Because of OCR bill scanning, manual data entry mistakes are being prevented.",
    full_label: "Uses Computer Vision technology coupled with AI Machine Learning to deliver over a 95% accuracy rate.",
    fast_label: "Our API works synchronously, with an average processing time of 1.3 seconds per page for pdfs.",
    footer_label1: "For years, a key challenge faced by energy suppliers has been acquiring accurate sign-up and enrollment data from a customer’s power bill. Bill Reader solves that problem.",
    footer_label2: "Allows clients to accurately capture critical sign-up and enrollment data including Service Address, ESIID and Meter Number.",
    footer_label3: "Users love the ease and flexibility of being able to simply snap a bill photo or upload a bill image to start sign-up.",
    footer_label4: "Significantly improves the flow of customers through the online sales funnel because accurate information is gathered from the very start.",
}

export default homeData;
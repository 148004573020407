
import {  Button, Input } from '@mui/material';
import { message,Alert } from 'antd';
import { useEffect, useState } from 'react';
import { isEmail } from '../../component/Comps';
import { contactUs } from '../service/fetch_example';
import '../styles/contact.scss';

export const Contact = () => {

    const [data, setData] = useState<any>({ site: 'billreader' });
    const [submitStatus, setSubmitStatus] = useState(false);
    useEffect(() => {
        toTop();
    }, [])

    const toTop = () => {
        window.scroll({
            top: 0,
            behavior: 'smooth'
        });

    };

    const handleOnChange = (e: any, type: string) => {
        let _data = data;
        _data[`${type}`] = e;
        setData(_data);
        console.log(_data);
    }


    const handleSubmit = () => {
        let { name, email, company, _message } = data || {};


        if (name == null || name.trim() === "") {
            message.warning('Please enter your name.');
            return;
        }
        if (email == null || email.trim() === "" || !isEmail(email)) {
            message.warning('Please enter the correct email format.');
            return;
        }
        if (company == null || company.trim() === "") {
            message.warning('Please enter your company.');
            return;
        }
        if (_message == null || _message.trim() === "") {
            message.warning('Please enter your message.');
            return;
        }
        contactUs(data).then((res) => {
            console.log(res);
            setSubmitStatus(true);
        })

    }

    return <div className="contactPage" >
        <h1>Contact Us</h1>
        <h4>Our team is happy to answer your questions. Fill out the form and we'll be in touch as soon as possible.</h4>

        <div className='form'>
            {!submitStatus && <div>
                <ul className='first'>
                    <li>
                        <p>Your Name</p>
                        <input onChange={(e) => { handleOnChange(e.target.value, 'name') }} />
                    </li>
                    <li>
                        <p>Email</p>
                        <input onChange={(e) => { handleOnChange(e.target.value, 'email') }} />
                    </li>
                </ul>
                <div className='company'>
                    <p>Company</p>
                    <input onChange={(e) => { handleOnChange(e.target.value, 'company') }} />
                </div>

                <div className='message'>
                    <p>Message</p>
                    <textarea onChange={(e) => { handleOnChange(e.target.value, '_message') }} />
                </div>
                <Button onClick={handleSubmit} >Submit</Button>
            </div>}

            {submitStatus &&<Alert
                    message="Submit Successfully."
                    description="We will contact you ASAP."
                    type="success"
                    showIcon
                    style={{ backgroundColor: 'unset', border: 'none',color:'white' }}
                /> }
        </div>
    </div>
}
import { Divider } from "@mui/material";
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import "../pages/styles/footer.scss"

const contentWidth = document.documentElement.clientWidth || document.body.clientWidth;

export const Footer = () => {

    const [isPhone, setIsPhone] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        getWidth();
    }, [])
    const getWidth = () => {
        if (contentWidth <= 600) {
            setIsPhone(true)
        } else {
            setIsPhone(false)
        }
    }




    return (<div>
        <Divider className="top-divider" />
        <div className="footer">
            <div className="foot-left">
                {/* <h2>Powered by POWERLEGO</h2> */}
                <p>Foundational building block enabling the digital<br />
                    transformation of the retail energy industry</p>
            </div>
            {isPhone && <Divider />}
            <div className="foot-right">
                <ul className="products">
                    <li>Products</li>
                    <li onClick={() => { window.open('https://www.powerlego.com') }}>PowerLego</li>
                    <li onClick={() => { window.open('https://www.eiqhome.com') }}>EIQhome</li>
                    <li onClick={() => { window.open('https://www.eiqshopping.com') }}>EIQShopping</li>
                    <li onClick={() => { window.open('https://www.ESIID.io') }}>ESIID.io</li>
                    <li onClick={() => { window.open('https://www.BillReader.com') }}>BillReader</li>
                </ul>
                {isPhone && <Divider />}
                <ul className="company">
                    <li>Company</li>
                    <li onClick={() => { navigate('/about_us') }}>About Us</li>
                    <li onClick={() => { navigate('/terms_of_use') }}>Terms of Use</li>
                    <li onClick={() => { navigate('/privacy_policy') }}>Privacy Policy</li>
                </ul>
                {isPhone && <Divider />}
                <ul className="support">
                    <li>Support</li>
                    <li onClick={() => { navigate('/contact') }}>Contact Us</li>
                    {/* <li onClick={() => { navigate('/faq') }}>FAQ</li> */}
                </ul>
                {isPhone && <Divider />}
                <ul className="secure">
                    <li>Secure</li>
                    <li><img src={require('../pages/images/logo/amazon_logo.png')} alt="" /></li>
                    <li><img src={require('../pages/images/logo_eiqdigital.png')} alt=""/></li>
                </ul>
            </div>
        </div>
    </div>)
}

import { NavLink, Outlet } from "react-router-dom";
import { Footer } from "../../component/Footer";
import "../styles/homeWrap.scss";

export const HomeWrap = () => {


    return <div className="homeWrap">
        <div className='top-menu'>
            <div className='left'>
                <NavLink to='/home'><span className='home_link'>Bill Reader</span></NavLink>
            </div>
            <div className='right'>
                <NavLink to='/about_us'><span className='about_us'>ABOUT US</span></NavLink>
                <NavLink to='/contact'><span className='contact'>CONTACT US</span></NavLink>
            </div>
        </div>

        <div>
            <Outlet />
        </div>
        <Footer />
    </div >;
}
// fetch.ts
const ACC_KEY = "c5fb349da68ab8094c07a799e99e09ce";
const API_URL = "https://www.powerlego.com/ApiGateway/";


export const uploadImg = async (val: any) => {
    
    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("file", val);

    const response = await fetch(`${API_URL}v1/bill_reader`, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    })
    return response.json()
}

//fetch email
export const contactUs = async (val:any) => {
    let {site , name , email , company , _message} = val;
    const _site = site.toString().replace(/#/, '');
    const _name = name.toString().replace(/#/, '')
    const _email = email.toString().replace(/#/, '')
    const _company = company.toString().replace(/#/, '')
    const message = _message.toString().replace(/#/, '')

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${ACC_KEY}`);

    const formdata = new FormData();
    formdata.append("site", _site);
    formdata.append("name", _name);
    formdata.append("email", _email);
    formdata.append("company", _company);
    formdata.append("message", message);

    const response = await fetch(`https://www.powerlego.com/contact/website`, {
        method: 'POST',
        headers: myHeaders,
        body: formdata
    })
    return response.json()
}

import { Button, CircularProgress, Tab, Tabs } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadImg } from '../pages/service/fetch_example';
import "../pages/styles/dropzone.scss";
import { getTable, ViewPdf } from './Comps';


function DropzoneComponent(props: any) {
    const [files, setFiles] = useState([]);
    const [isPdf, setIsPdf] = useState(false);
    const [data, setData] = useState<any>();
    const [addrAndmeterId, setAddrAndMeterId] = useState<any>();
    const [index, setIndex] = useState(1);
    const [loading, setLoading] = useState(false);

    const [isPc, setIsPc] = useState(true);

    useEffect(() => {
        getWidth();
    }, [])
    const getWidth = () => {
        let width = document.body.clientWidth;
        if (width < 1200) {
            setIsPc(false);
        }
    }


    const onDrop = useCallback((acceptedFiles: any) => {
        if (loading) return;
        setLoading(true);
        uploadImg(acceptedFiles[0]).then((res) => {
            if (!('errorCode' in res)) {
                setData(res);
            } else {
                setData('');
            }
            setLoading(false);

        })
        setFiles(acceptedFiles.map((file: any) => Object.assign(file, {
            preview: URL.createObjectURL(file)
        })));
    }, []);

    const {
        getRootProps,
        getInputProps
    } = useDropzone({
        onDrop
    });

    const thumbs = files.map((file: any) => {

        return (<div key={file.name}>
            {isPdf && isPc && <iframe
                src={file.preview}
                title={file.name}
            />
            }

            {isPdf && !isPc && <ViewPdf url={file.preview} path={file.path} />}

            {!isPdf && <img src={file.preview}
                alt={file.name} />}
        </div>
        )
    });

    // clean up
    useEffect(() => () => {
        files.forEach((file: any) => URL.revokeObjectURL(file.preview));
    }, [files]);

    //pdf or other
    useEffect(() => {
        let file: any = files[0] || {};
        if (file.type === "application/pdf") {
            setIsPdf(true)
        } else {
            setIsPdf(false)
        }
    }, [files])

    //get data
    useEffect(() => {
        if (data) {
            setAddrAndMeterId(getTable(data));
        }
    }, [data])

    return (
        <div>
            <section className='pc-dropzone'>
                <div className='left'>
                    <div  {...getRootProps()} className="drop">
                        <input {...getInputProps()} disabled={loading} />
                        {
                            files.length > 0 ?
                                <div>
                                    <p>Upload another bill</p>
                                </div> :
                                <div>
                                    <p>Upload an electricity bill</p>
                                    <img src={require("../pages/images/icon_upload.png")} alt="" />
                                </div>
                        }
                    </div>
                    <aside>
                        {thumbs}
                    </aside>
                </div>
                <div className="right">
                    {
                        loading && <div className='bill-data'><CircularProgress /></div>
                    }
                    {
                        (data && !loading && addrAndmeterId) &&
                        <div className='bill-data'>
                            <ul className='top'>
                                <li className={index === 1 ? "onClick" : ""}><Button variant="text" onClick={() => setIndex(1)}>Table</Button></li>
                                <li className={index === 2 ? "onClick" : ""}><Button variant="text" onClick={() => setIndex(2)}>Json</Button></li>
                            </ul>
                            <div className='tabs'>
                                {
                                    index === 1 &&
                                    <ul >
                                        <li>
                                            Meter ID
                                            <p>{addrAndmeterId.meterId || " - "}</p>
                                        </li>
                                        <li>
                                            ESIID
                                            {addrAndmeterId.esiid.length === 0 && <p>-</p>}
                                            {addrAndmeterId.esiid.length > 0 && addrAndmeterId.esiid.map((a: any, index: number) => {
                                                return <p key={index}>{a}</p>
                                            })}
                                        </li>
                                        <li>
                                            Address
                                            {addrAndmeterId.address.length === 0 && <p>-</p>}
                                            {addrAndmeterId.address.length === 1 && <p>{addrAndmeterId.address[0]}</p>}
                                            {addrAndmeterId.address.length > 1 && addrAndmeterId.address.map((a: any, index: number) => {
                                                return <p key={index}>Address{index + 1} : {a}</p>
                                            })}
                                        </li>

                                        <li>
                                            Street
                                            <p>{addrAndmeterId.addr.length === 0 ? " - " : addrAndmeterId.addr[0].road || '-'}</p>
                                        </li>
                                        <li>
                                            City
                                            <p>{addrAndmeterId.addr.length === 0 ? " - " : addrAndmeterId.addr[0].city || '-'}</p>
                                        </li>
                                        <li>
                                            Zipcode
                                            <p>{addrAndmeterId.addr.length === 0 ? " - " : addrAndmeterId.addr[0].postcode || '-'}</p>
                                        </li>
                                        {
                                            data.ext && data.ext.company && <li>
                                                Company
                                                <p>{data.ext.company}</p>
                                            </li>
                                        }
                                        {
                                            data.ext && data.ext.puct && <li>
                                                PUCT
                                                <p>{data.ext.puct}</p>
                                            </li>
                                        }
                                    </ul>
                                }
                                {
                                    index === 2 &&
                                    <pre>{JSON.stringify(data, null, 2)}</pre>
                                }
                            </div>
                        </div>
                    }
                </div>
            </section>

            <section className='mobile-dropzone'>
                <div  {...getRootProps()} className="drop">
                    <input {...getInputProps()} disabled={loading} />
                    {
                        files.length > 0 ?
                            <div>
                                <p>Upload another bill</p>
                                <img src={require("../pages/images/icon_upload.png")} alt="" />
                            </div> :
                            <div>
                                <p>Upload an electricity bill</p>
                                <img src={require("../pages/images/icon_upload.png")} alt="" />
                            </div>
                    }
                </div>

                <aside>
                    {thumbs}
                </aside>
                {
                    loading && <div className='bill-data'><CircularProgress /></div>
                }
                {
                    (data && !loading && addrAndmeterId) &&
                    <div className='bill-data'>
                        <ul className='top'>
                            <li className={index === 1 ? "onClick" : ""}><Button variant="text" onClick={() => setIndex(1)}>Table</Button></li>
                            <li className={index === 2 ? "onClick" : ""}><Button variant="text" onClick={() => setIndex(2)}>Json</Button></li>
                        </ul>
                        <div className='tabs'>
                            {
                                index === 1 &&
                                <ul >
                                    <li>
                                        <strong>Meter ID &nbsp; &nbsp;</strong>
                                        <span>{addrAndmeterId.meterId || " - "}</span>
                                    </li>
                                    <li>
                                        <strong>ESIID &nbsp; &nbsp;</strong>
                                        {addrAndmeterId.esiid.length === 0 && <span>-</span>}
                                        {addrAndmeterId.esiid.length !== 0 && addrAndmeterId.esiid.map((a: any, index: number) => {
                                            return <span key={index}>{a}</span>
                                        })}
                                    </li>
                                    <li>
                                        <strong>Address</strong>
                                        {addrAndmeterId.address.length === 0 && <span>-</span>}
                                        {addrAndmeterId.address.length === 1 && <span>{addrAndmeterId.address[0] || '-'}</span>}
                                        {addrAndmeterId.address.length > 1 && addrAndmeterId.address.map((a: any, index: number) => {
                                            return <p key={index}>Address &nbsp;{index + 1} : {a}</p>
                                        })}
                                    </li>

                                    <li>
                                        <strong>Street &nbsp; &nbsp;</strong>
                                        <span>{addrAndmeterId.addr.length === 0 ? " - " : addrAndmeterId.addr[0].road}</span>
                                    </li>
                                    <li>
                                        <strong>City &nbsp; &nbsp;</strong>
                                        <span>{addrAndmeterId.addr.length === 0 ? " - " : addrAndmeterId.addr[0].city}</span>
                                    </li>
                                    <li>
                                        <strong>Zipcode &nbsp; &nbsp;</strong>
                                        <span>{addrAndmeterId.addr.length === 0 ? " - " : addrAndmeterId.addr[0].postcode}</span>
                                    </li>
                                    {
                                        data.ext && data.ext.company && <li>
                                            <strong>Company  &nbsp; &nbsp;</strong>
                                            <span>{data.ext.company}</span>
                                        </li>
                                    }
                                    {
                                        data.ext && data.ext.puct && <li>
                                            <strong>PUCT &nbsp; &nbsp;</strong>
                                            <span>{data.ext.puct}</span>
                                        </li>
                                    }
                                </ul>
                            }
                            {
                                index === 2 &&
                                <pre>{JSON.stringify(data, null, 2)}</pre>
                            }
                        </div>
                    </div>
                }
            </section>
        </div>
    )
}

export default DropzoneComponent;
import { HashRouter, useRoutes } from "react-router-dom";
import { FAQ, PrivacyPolicy, TermsOfUse } from "./component/FooterLinkPages";
import { AboutUs } from "./pages/home/AboutUs";
import { Contact } from "./pages/home/ContactUs";
import { Home } from "./pages/home/Home";
import { HomeWrap } from "./pages/home/HomeWrap";

const GetRoutes = () => {
    const routes = useRoutes(
        [
            {
                path: '/', element: <HomeWrap />, children: [
                    { index: true, element: <Home /> },
                    { path: 'home', element: <Home /> },
                    { path: '/about_us', element: <AboutUs /> },
                    { path: '/contact', element: <Contact /> },
                    { path: '/faq', element: <FAQ /> },
                    { path: '/privacy_policy', element: <PrivacyPolicy /> },
                    { path: '/terms_of_use', element: <TermsOfUse /> },
                ]
            },
        ]);
    return routes;
}

const InitRoutes = () => {
    return (
        <HashRouter>
            <GetRoutes />
        </HashRouter>
    )
}

export default InitRoutes;
export const PrivacyPolicyContent = () => {
    return (
        <>
            <p>The BillReader.com website (“the Service”) is owned and operated by eIQdigital, LLC (“eIQdigital” “we” or “us”).  This Privacy Policy describes our practices concerning the information we collect from you when you visit or use the Service. By visiting or using the Service, you consent to our collection and use of your information as described in this Privacy Policy.</p>
            <h3>1. Information We Collect</h3>
            <p>The information that we collect from you depends upon what sections of the Service you visit and what actions you take during your visit.  In general, we collect the following kinds of information:</p>
            <p><b>(a) General Information:</b> General Information is information that is anonymous in nature and does not identify you as an individual.  We collect General Information by using “cookies”, “web beacons” and similar tools, and it may include your computer IP address, unique mobile device identifier, browser type, ISP or carrier name, the URL of the last webpage you visited before visiting the Service, and actions you undertake on the Service when you visit. This information gives us insights on how people use the Service and may result in us tailoring advertisements on the Service to you based on your online activities or preferences.</p>
            <p><b>(b) Social Media-Related Content</b><br />The Service may include features that let you share information from it to social media services. Your use of these sharing features may result in the collection or sharing of information about you by these sites and other third parties according to the social media services’ own privacy policies.</p>
            <h3>2. How We Share Information</h3>
            <p>We only collect General Information that is anonymous in nature and does not identify you as an individual. We never share this information with any 3rd parties.</p>
            <h3>3. How We Protect Information</h3>
            <p>We take the security of your information seriously and use reasonable technical and organizational measures to protect against unauthorized or unlawful processing and against accidental loss, destruction or damage on any information including the General Information we collect.</p>
            <p>However, because no security system can be 100% effective, we do not guarantee the security of any anonymous information we may have collected from or about you.  In addition, we have no control over the security of other web sites on the Internet that you might visit even when a link may appear to those websites.  If there is a breach of security, then we may either post a notice through the service or attempt to notify you via email so that you can take reasonable protective steps.</p>
            <h3>4. How Long We Keep Information</h3>
            <p>We may retain information you provide to us for as long as we deem necessary to fulfill a business purpose or comply with legal requests.</p>
            <h3>5. Your Rights and Control</h3>
            <p>You may at any time (i) configure your browser to opt out of the Service’s placement of cookies on your computer; (ii) stop receiving commercial emails from us by clicking the “unsubscribe” link at the bottom of the email; (iii) we may keep some of the information we gather in an anonymized form that does not reveal identifying information about you. You may exercise these rights by contacting us as provided in Section 10 or in other written communications we may send you.</p>
            <h3>6. Third-Party Links</h3>
            <p>The Service may include links to our business partners’ websites or other sites. If you choose to provide any information to any of these entities, they will collect and use your information pursuant to their own privacy policies, over which we have no control.</p>
            <h3>7. Tracking Technology and Do-Not-Track Requests</h3>
            <p>We and some service providers we work with may employ technology that tracks your online activities on the Service and on third-party websites. We currently offer no response to Do-Not-Track requests transmitted by web browsers. In addition, we have no control over the activities of third-party, advertiser, or service provider practices and they may employ technology that tracks your activity on the Service.</p>
            <h3>8. Children under 13</h3>
            <p>Our Service is not for users under the age of 13, and we do not knowingly collect any information from children under 13.</p>
            <h3>9. Changes to this Privacy Policy</h3>
            <p>We may change this Privacy Policy in the future. If we make any changes regarding the disclosure of Personal Information to third parties, we will inform you via email or by posting a notice on our website.  By continuing to use the Service following any such change, you agree to the amended Privacy Policy.</p>
            <h3>10. How to Contact Us</h3>
            <p>With any questions about this policy, please <a href="/#/contact">Contact Us</a>.</p>
        </>
    )
}